import { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import appContext from '../../src/viewer-app-module/DataBindingAppContext'
import { AppError } from '../../src/logger'
import {
  getUserFilterInitialData as getUserFilterInitialDataFromState,
  UserFilterMinMaxInitialData,
} from '../../src/dataset-controller/rootReducer'
import { AdaptedComponent } from '../inverted-dependencies/components'
import { AdaptedRangeSlider } from '../inverted-dependencies/components/rangeSliderAdapter'
import { UoUFilterConnectionConfig } from '../types'

export const userInputFilterRangeSliderBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponent,
  UoUFilterConnectionConfig
> = (component, connectionConfig, context) => {
  const { errorReporting } = appContext
  const { actions, getState } = context

  const getUserFilterInitialData = (fieldName: string) =>
    getUserFilterInitialDataFromState(getState())?.find(
      userFilterInitialData =>
        userFilterInitialData.fieldName === fieldName &&
        userFilterInitialData.role === component.role,
    )

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),
    bind() {
      const { userInputFilter } = connectionConfig

      component.onChange(
        errorReporting(
          actions.refresh,
          AppError.withMessage('Filter input adapter onChange failed'),
        ),
      )

      const { fieldName } = userInputFilter

      const userFilterInitialData = getUserFilterInitialData(fieldName)

      if (userFilterInitialData) {
        const { min, max } =
          userFilterInitialData as UserFilterMinMaxInitialData
        component.setValue([min, max])
        ;(component as AdaptedRangeSlider).setBounds(min, max)
        return
      }
    },
  }
}
